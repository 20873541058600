import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom';

// Import GUI:
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, Toolbar, Box, MenuItem, Drawer, AppBarProps as MuiAppBarProps, Container, Typography } from '@mui/material';
import { CustomThemeContext }  from './CustomThemeProvider';

import { OutlineButton, OutlineButtonInv, SolidButton, SolidButtonInv, TextButton, TextButtonInv } from '../components/Button';
import Intro from '../blocks/Intro';
import Motto from '../blocks/Motto';
import About from '../blocks/About';
import Function from '../blocks/Function';
import Usage from '../blocks/Usage';
import FAQ from '../blocks/FAQ';
import Testers from '../blocks/Testers';
import Press from '../blocks/Press';
import Contacts from '../blocks/Contacts';
import Footer from '../blocks/Footer';

import { ReactComponent as LotusLogo } from '../assets/lotus-foil-logo.svg';
import { ReactComponent as LotusLogoLight } from '../assets/lotus-foil-logo_light.svg';
import Benefits from '../blocks/Benefits';
import { navigateTo, openNews } from './Util';


interface IState {
	anchorElement : any
	mobileMoreAnchorElement : any
	languageAnchorElement : any
	sidebarOpened : boolean
	currentLanguage : string
}

interface IProps extends WithTranslation {
	modules: any[],
	setLanguage : (x: string) => void,
	currentLanguage : string,
	children?: React.ReactNode,	
}

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;	
}


const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
	easing: theme.transitions.easing.sharp,
	duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
	marginLeft: '100%',
	width: '0',
	transition: theme.transitions.create(['width', 'margin'], {
	  easing: theme.transitions.easing.sharp,
	  duration: theme.transitions.duration.enteringScreen,
	}),
  }),
}));


class Base extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {
			anchorElement : null,
			mobileMoreAnchorElement : null,
			languageAnchorElement : null,
			sidebarOpened : false,
			currentLanguage : this.props.currentLanguage || "en"
		};
		this.handleMenuClose = this.handleMenuClose.bind(this);
		this.handleMenuOpen = this.handleMenuOpen.bind(this);
		this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
		this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
	}

	handleMenuClose(e : any, after : any = null) {
		if (typeof(after) === "function")
			this.setState({anchorElement : null, languageAnchorElement : null}, after);
		else
			this.setState({anchorElement : null, languageAnchorElement : null});
		this.handleMobileMenuClose(e);
	};

	handleMenuOpen(e : any) {
		this.setState({anchorElement : e.currentTarget});
	};

	handleMobileMenuOpen(e: any) {
		this.setState({mobileMoreAnchorElement : e.currentTarget});
	}

	handleMobileMenuClose(e : any) {
		this.setState({mobileMoreAnchorElement : null});
	};
	
	handleLanguageChange(e: any, lang: string)
	{
		e.stopPropagation();
		this.props.setLanguage(lang);
		this.setState({ currentLanguage: lang });
		return false;
	}
	
	render() {	
		const t = this.props.t;
		
		const mobileMenuId = 'primary-menu-mobile';
		const mobileMenuLinkStyle = { padding: '.25em .5em', fontSize: '120%' };
		const langButtonStyle = { textTransform: 'uppercase', fontSize: '12px', p: '0.5em 1.1em', minWidth: 'unset', display: 'inline' };
		
		const container = window !== undefined ? () => window.document.body : undefined;
		const mobileMenuNumber = (number: string) => <Typography color="primary.contrastText" sx={{ fontSize: '12px !important', mr: '1em' }}>{number}</Typography>;

		// ========== ========== ==========
		return(<CustomThemeContext.Consumer>{ theme => (
		<BrowserRouter><Box sx={{ display: 'flex' }}>		
		<CssBaseline />
		
		{/* { <Navigate to={this.state.navigateFromSearch} /> } */}
		
		<StyledAppBar position="fixed" open={this.state.sidebarOpened} 
			sx={{ 
				p: { xs: '44px 30px', md: '44px 60px'},
				/*bgcolor: { xs: 'background.paper', md: 'transparent'}, */
				bgcolor: 'background.paper',
				zIndex: 1,
				maxWidth: 'xl',
				position: "fixed", left:"50%", transform: 'translate(-50%, 0%)',
				borderRadius: "0px 0px 41px 41px",
				height: '140px'
			}}
		>
			<Container maxWidth='xl' disableGutters>

			{!Boolean(this.state.mobileMoreAnchorElement) && <Toolbar disableGutters>
					<LotusLogo style={{ alignSelf: 'center', flexShrink: 1 }} />

					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none', lg: 'flex' }, columnGap: '8px', justifyContent: 'flex-end' }}>

						<TextButton onClick={() => navigateTo('#about')}>{t("menu.lotus-foil")}</TextButton>
						<TextButton onClick={() => navigateTo('#benefits')}>{t("menu.benefits")}</TextButton>
						<TextButton onClick={() => openNews(this.props.currentLanguage)}>{t("menu.news")}</TextButton>
						<TextButton onClick={() => navigateTo('#references')}>{t("menu.references")}</TextButton>
						<Divider />

						<SolidButton onClick={() => navigateTo('#contact')}>{t("menu.contact")}</SolidButton>
						{this.state.currentLanguage !== "en" &&
							<OutlineButton sx={langButtonStyle} onClick={(e) => this.handleLanguageChange(e, "en")}>{t("menu.en")}</OutlineButton>}
						{this.state.currentLanguage !== "cs" &&
							<OutlineButton sx={langButtonStyle} onClick={(e) => this.handleLanguageChange(e, "cs")}>{t("menu.cs")}</OutlineButton>}
					</Box>

					<Box sx={{ flexGrow: 1, display: { xs: 'flex',  md: 'flex', lg: 'none' }, justifyContent: 'flex-end' }}>
						<SolidButton color="primary" size="small" sx={{ p: '4px 8px', ml: '16px' }}
						aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" 
							onClick={this.handleMobileMenuOpen}>
							<MenuIcon />
						</SolidButton>
					</Box>

				</Toolbar>}
			</Container>
		</StyledAppBar>
		
		<Box component="nav">
			<Drawer
				anchor="right"
				container={container}
				variant="temporary"
				open={Boolean(this.state.mobileMoreAnchorElement)}
				onClose={this.handleMobileMenuClose}
				ModalProps={{keepMounted: true}} sx={{
					display: { md: 'block', lg: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
				}}>

					<Box onClick={this.handleMobileMenuClose}
						sx={{ bgcolor: 'primary.main', height: '100%', p: { xs: '14px', md: '14px 46px' }, display: 'flex', flexDirection: 'column' }}>
						<Box sx={{ padding: '44px 16px 0', display: 'flex', justifyContent: 'space-between' }}>
							<LotusLogoLight style={{ alignSelf: 'center', maxWidth: '100%', height: 'unset', flexShrink: 1 }} />

							<SolidButtonInv color="primary" size="small" sx={{ p: '4px 8px', ml: '16px' }} onClick={this.handleMobileMenuClose}>
								<CloseIcon />
							</SolidButtonInv>
						</Box>

						<div style={{ flexGrow: 2, display: 'flex', flexDirection: 'column', rowGap: '1.2em', justifyContent: 'center' }}>
						<MenuItem className="menu-label">
							{mobileMenuNumber('/01')}
							<TextButtonInv sx={mobileMenuLinkStyle} onClick={() => navigateTo('#about')}>{t("menu.lotus-foil")}</TextButtonInv>
						</MenuItem>
						<MenuItem className="menu-label">
							{mobileMenuNumber('/02')}
							<TextButtonInv sx={mobileMenuLinkStyle} onClick={() => navigateTo('#benefits')}>{t("menu.benefits")}</TextButtonInv>
						</MenuItem>
						<MenuItem className="menu-label">
							{mobileMenuNumber('/03')}
							<TextButtonInv sx={mobileMenuLinkStyle} onClick={() => navigateTo('#new')}>{t("menu.news")}</TextButtonInv>
						</MenuItem>
						<MenuItem className="menu-label">
							{mobileMenuNumber('/04')}
							<TextButtonInv sx={mobileMenuLinkStyle} onClick={() => navigateTo('#references')}>{t("menu.references")}</TextButtonInv>
						</MenuItem>
						<MenuItem className="menu-label">
							{mobileMenuNumber('/05')}
							<TextButtonInv sx={mobileMenuLinkStyle} onClick={() => navigateTo('#contact')}>{t("menu.contact")}</TextButtonInv>
						</MenuItem>
						</div>

						<Box sx={{ marginBottom: '60px', alignSelf: 'flex-end' }}>
						{this.state.currentLanguage !== "en" &&
							<MenuItem className="menu-label" onClick={(e)=>{  this.handleLanguageChange(e, "en"); }}>
								<OutlineButtonInv sx={langButtonStyle} size='small'>{t("menu.en")}</OutlineButtonInv>
							</MenuItem>}
						{this.state.currentLanguage !== "cs" &&
							<MenuItem className="menu-label" onClick={(e)=>{  this.handleLanguageChange(e, "cs"); }}>
								<OutlineButtonInv sx={langButtonStyle} size='small'>{t("menu.cs")}</OutlineButtonInv>
							</MenuItem>}
						</Box>
					</Box>

			</Drawer>
		</Box>

		<Container disableGutters maxWidth='xl' sx={{ display: 'flex', flexDirection: 'column'}}>
			<div id='intro'><Intro/></div>
			<Motto />
			<div id='about'><About /></div>
			<div id='function'><Function /></div>
			<div id='usage'><Usage /></div>
			<div id='benefits'><Benefits /></div>
			<div id='references'><Testers /></div>
			<Press />
			<div id='contact'><Contacts/></div>
			<FAQ />
			<Footer />
		</Container>
		
	</Box></BrowserRouter>)}</CustomThemeContext.Consumer>)
	}
}

export default withTranslation()(Base);