import React from 'react'
import { Accordion as MuiAccordion, Box, BoxProps, SvgIconProps, SvgIcon } from '@mui/material';
import { SxProps } from '@mui/system';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Trans } from 'react-i18next';

interface IState {
    expanded: number | null
}

interface IProps {
    items: any[],
    summaryKey: string,
    detailsKey: string,
    lightBg: boolean,
    sx?: SxProps,
    boxProps?: BoxProps,
}
function ExpandMoreIcon(props: SvgIconProps) : JSX.Element {
    return <SvgIcon {...props}>
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
    </SvgIcon>
}
class Accordion extends React.Component<IProps, IState> {

    static defaultProps: Partial<IProps> = { lightBg: true, summaryKey: 'summary', detailsKey: 'details' };

	constructor(props: IProps){		
		super(props);				
		this.state = {
			expanded: null
		};

		this.expand = this.expand.bind(this);
	}

	expand(index: number) {
		this.setState({ expanded: this.state.expanded === index ? null : index });
		return false;
	}

	render() {
        const fgColor = this.props.lightBg ? 'primary.main' : 'primary.contrastText';

		return <Box sx={{ ...this.props.sx, pr: 2 }} {...this.props.boxProps}>
                {this.props.items.map((item, index) =>
                    <MuiAccordion 
                        expanded={this.state.expanded === index} 
                        onChange={() => this.expand(index)} sx={{ color: fgColor }} 
                        key={index} square
                    >
                        <AccordionSummary  expandIcon={<ExpandMoreIcon color='primary'/>}>
                            <Typography variant='body2'>{item[this.props.summaryKey]}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body3'>
                                <Trans>
                                    {item[this.props.detailsKey]}
                                </Trans>
                            </Typography>
                        </AccordionDetails>
                    </MuiAccordion>
                )}
            </Box>;
	}
}

export default Accordion;