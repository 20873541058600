import { Typography } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles'

import { Parallax } from 'react-scroll-parallax';
import {ReactComponent as Slashes} from '../assets/slashes.svg';
import { Box, SxProps } from '@mui/system';

interface IState {
}

interface IProps {
    children?: React.ReactNode,
    variant: 'h1' | 'h2' | 'h3',
    lightBg: boolean,
    showIcon: boolean,
    offsetIcon: boolean,
    sx?: SxProps,
    firstSlashStart: {x: any, y: any},
    secondSlashStart: {x: any, y: any},
}

class Heading extends React.Component<IProps, IState> {

    static defaultProps: IProps = { variant: 'h1', lightBg: true, showIcon: true, offsetIcon: false, firstSlashStart:{x: 2.4, y: -3.6}, secondSlashStart:{x: -1.4, y: 1.4} };

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {
        const fgColor = this.props.lightBg ? 'primary.main' : 'primary.contrastText';

        const Icon = styled(Slashes)(({ theme }) => ({ fill:
            this.props.lightBg ? theme.palette.primary.main : theme.palette.primary.contrastText,
            marginRight: '0.2em',
            width: '1.1em',
            height: '1.1em',
        }));
        return <>
        {this.props.showIcon && <Box display="flex" flexDirection="row" justifyContent="start">
            <Typography sx={{ position: "relative", right: "1.3em"}} variant={this.props.variant} color={fgColor}>
                <Parallax style={{
                        display: 'inline-block',
                        position: 'relative',
                        left: "0.8em",
                        top: "0.18em"
                    }} className='parallax'
                    /*translateX={["2vw", "-3vw", 'easeIn']} translateY={["-2vw", "3vw", 'easeIn']}*/
                    translateX={[this.props.firstSlashStart.x.toString() + "vw", "0vw", 'easeOutExpo']} 
                    translateY={[this.props.firstSlashStart.y.toString() + "vw", "0vw", 'easeOutExpo']}
                >
                    <Icon />
                </Parallax>
                
                </Typography>
                <Typography sx={{ minWidth: "104%", position: "relative", right: "1.3em", ...this.props.sx}} variant={this.props.variant} color={fgColor}>
                <Parallax style={{
                    display: 'inline-block',
                    position: 'relative',
                    top: "0.18em"
                }} className='parallax'
                translateX={[this.props.secondSlashStart.x.toString() + "vw", "0vw", 'easeOutExpo']} 
                translateY={[this.props.secondSlashStart.y.toString() + "vw", "0vw", 'easeOutExpo']} 

                >
                    <Icon />
                </Parallax>
                {this.props.children}
            </Typography>
        </Box>}
        {!this.props.showIcon && 
            <Typography sx={{ ...this.props.sx }} variant={this.props.variant} color={fgColor}>
                {this.props.children}
            </Typography>
        }
        </>
	}
}

export default Heading;