import React from 'react'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Description from '../components/Description';
import Emphasis from '../components/Emphasis';
import Grid from '@mui/material/Grid/Grid';
import { SolidButton } from '../components/Button';

import { Parallax } from 'react-scroll-parallax';
import doctor from '../assets/doctor.png';
import { ReactComponent as DoctorShapesLight} from '../assets/doctor_shapes_light.svg';
import { ReactComponent as DoctorShapesDark} from '../assets/doctor_shapes_dark.svg';
import { navigateTo } from '../system/Util';

interface IState {
}

interface IProps extends WithTranslation {
}

class Function extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';

		return <Block color='background.default'>

            <Grid container spacing={6} className='full-height' sx={{ flexDirection: { xs: 'column-reverse', md: 'unset' }}}>
                <Grid item md={6}>
                    <Heading>
                        {t('what-lotus-does', {ns})}
                    </Heading>

                    <Description>
                        <Trans i18nKey='lotus-function' ns={ns} components={[ <Emphasis /> ]} />
                    </Description>

                    <SolidButton onClick={() => navigateTo('#benefits')}>{t('lotus-benefits', {ns})}</SolidButton>
                </Grid>

                <Grid item md={6} className='illustration'>
                    <Parallax className='parallax' speed={-11}>
                        <DoctorShapesLight />
                    </Parallax>
                    <Parallax className='parallax' speed={7}>
                        <DoctorShapesDark />
                    </Parallax>
                    <Parallax className='parallax' speed={1}>
                        <img src={doctor} alt='' />
                    </Parallax>
                </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(Function);