import React from 'react'
import '../app.css';
import { Grid, Card as MuiCard, CardProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box, SxProps } from '@mui/system';

interface ICardItem {
    title: string,
    description: string,
    icon: React.ReactElement
}

interface IState {
    flipped: boolean
}

interface IProps {
    item: ICardItem,
    lightBg: boolean,
    sx?: SxProps,
    cardProps?: CardProps,
}

class Card extends React.Component<IProps, IState> {

    static defaultProps: Partial<IProps> = { lightBg: true };

    w_h = {
        width: "380px",
        height: "190px"
    }
    
	render() {
        const { item, sx, cardProps } = this.props;
    
        return (<Box sx={{                          
                ...this.w_h
            }}>
            <div className="card-container" >
                <div className={`card-inner`}>
                    {/* Front Side */}
                    <div className="card-front">
                        <MuiCard
                            {...cardProps}
                            sx={{
                                bgcolor: "primary.main",
                                p: { xs: "20px", sm: "20px", md: "30px" },
                                ...this.w_h,
                                ...sx,
                            }}
                        >
                            <Grid container direction={"column"} justifyContent={"center"} sx={{height:"100%"}}>
                                <Grid container sx={{width: "60%"}} justifyContent={"end"} direction={'column'}>
                                    <Grid container sx={{height: "75px", paddingRight:"20px"}} direction="column" justifyContent="center">
                                        <Typography variant="h2" color="primary.contrastText">
                                            {item.title}
                                        </Typography>        
                                    </Grid>
                                </Grid>
                                
                                <Grid container sx={{width: "40%"}} alignItems={"center"} justifyContent={"center"} direction={'column'}>
                                    {item.icon}
                                </Grid>
                            </Grid>
                        </MuiCard>
                    </div>
    
                    {/* Back Side */}
                    <div className="card-back">
                        <MuiCard
                            {...cardProps}
                            sx={{
                                bgcolor: "primary.dark",
                                p: "0px 30px",
                                ...this.w_h,
                                ...sx,
                            }}
                        >
                            <Grid container direction="column" justifyContent="center" sx={{height:"100%"}}>    
                                <Typography variant="body3" fontSize="18px" color="primary.contrastText"
                                    dangerouslySetInnerHTML={{ __html: item.description}}/>   
                            </Grid>
                        </MuiCard>
                    </div>
                </div>
            </div>
        </Box>);
    }
}

export default Card;
export type { ICardItem };