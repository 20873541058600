import React from 'react'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Description from '../components/Description';
import Emphasis from '../components/Emphasis';

import tablet from '../assets/tablet.png';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface IState {
}

interface IProps extends WithTranslation {
}

class Intro extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';

		return <Block color='primary.main' rounded>

            <Grid2 container spacing={6} className='full-height' sx={{ flexDirection: { xs: 'column-reverse', md: 'unset' }}}>
                <Grid2 md={8} sx={{}}>
                    <Description lightBg={false} sx={{ m: 0 }}>
                        {t('motto', {ns})}
                    </Description>

                    <Heading lightBg={false} showIcon={false}>
                        <Trans i18nKey='safe-touch' ns={ns} components={[ <Emphasis lightBg={false} /> ]} />
                    </Heading>
                </Grid2>

                <Grid2 md={4} className='illustration'>
                    <svg/>
                    <img src={tablet} alt='' />
                </Grid2>
            </Grid2>
        </Block>;
	}
}

export default withTranslation()(Intro);