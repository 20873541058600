import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';

import { Box, Grid } from '@mui/material';
import { SolidButton } from '../components/Button';
import Card, { ICardItem } from '../components/Card';

import { ReactComponent as EnvironmentIcon } from '../assets/benefits/environment.svg';
import { ReactComponent as AllDayIcon } from '../assets/benefits/24h.svg';
import { ReactComponent as CovidIcon } from '../assets/benefits/covid.svg';
import { ReactComponent as CzIcon } from '../assets/benefits/cz.svg';
import { ReactComponent as IdeaIcon } from '../assets/benefits/idea.svg';
import { ReactComponent as DiagonalIcon } from '../assets/benefits/diagonal.svg';
import { ReactComponent as PanelIcon } from '../assets/benefits/panel.svg';
import { ReactComponent as PatentIcon } from '../assets/benefits/patent.svg';
import { ReactComponent as YearIcon } from '../assets/benefits/year.svg';

import { navigateTo } from '../system/Util';


const icons = [
    <EnvironmentIcon />,
    <AllDayIcon />,
    <PanelIcon />,
    <CovidIcon />,
    <DiagonalIcon />,
    <CzIcon />,
    <IdeaIcon />,
    <YearIcon />,
    <PatentIcon />
]

interface IState {
}

interface IProps extends WithTranslation {
}

class Benefits extends React.Component<IProps, IState, WithTranslation> {

    blockRef: HTMLDivElement | null = null;
    cardsRef: React.RefObject<HTMLDivElement>;

	constructor(props: IProps){		
		super(props);				
		this.state = {};

        this.cardsRef = React.createRef<HTMLDivElement>();
        this.attachScroll = this.attachScroll.bind(this);
        this.detachScroll = this.detachScroll.bind(this);
        this.onScroll = this.onScroll.bind(this);
	}

    componentWillUnmount(): void {
        this.detachScroll();
    }

    attachScroll(ref: HTMLDivElement) {
        this.detachScroll();
        this.blockRef = ref;
        if (!ref) return;
        ref.addEventListener('wheel', this.onScroll, { passive: false });
    }

    detachScroll() {
        if (this.blockRef)
            this.blockRef.removeEventListener('wheel', this.onScroll);
    }

    onScroll(e: React.WheelEvent<HTMLDivElement> | any) {
        const cards = this.cardsRef.current;
        if (!cards || !this.blockRef || e.deltaY === 0) // going sideways
            return true;
        
        // finished scrolling in movement direction
        if (e.deltaY > 0 && cards.clientWidth + cards.scrollLeft >= cards.scrollWidth)
            return true;
        if (e.deltaY < 0 && cards.scrollLeft === 0)
            return true;
            
        e.preventDefault();
        e.stopPropagation();
            
        const rect = this.blockRef.getBoundingClientRect();
        if (rect.top > 10 || rect.top < -10) // not entirely in viewport
            this.blockRef.scrollIntoView();
        else
            cards.scrollBy(e.deltaY, 0);
        return false;
    }

	render() {	
		const t = this.props.t;
        const ns = 'main';
        const cards = t<string, Partial<ICardItem>[]>('benefits', { ns, returnObjects: true },) ?? [];

		return <Block color='background.paper' rounded
                sx={{ maxWidth: '100vw', justifyContent: { xs: 'space-between', md: 'center' }, alignItems: {md: "center"},
                    padding: {xs: '70px 30px', md: '10px 62px 25px 62px'} }}
            >

                <Grid container sx={{paddingBlockEnd: "35px"}} justifyContent={"space-between"} direction="row">
                    <Box sx={{width: "70%"}}>
                        <Heading>
                            {t('what-benefits-lotus-has', {ns})}
                        </Heading>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                        <SolidButton onClick={() => navigateTo('#function')}>{t('what-lotus-foil-does', {ns})}</SolidButton>
                    </Box>
                </Grid>
                <Grid container spacing={2} sx={{maxWidth: "800px", "@media (min-width: 1320px)": {maxWidth: "1200px"}}}>
                    {cards.map((item, index) => 
                        <Grid item xs>
                            <Card key={index}  item={{
                                title: item.title ?? '',
                                description: item.description ?? '',
                                icon: icons[index % icons.length] }} 
                            />
                        </Grid>
                    )}
                </Grid>


                {/* <Box sx={{ width: '100%', height: 'calc(100% + 120px)', position: 'absolute', top: '-60px', left: 0, zIndex: -1, bgcolor: 'background.paper' }} /> */}

        </Block>;
	}
}

export default withTranslation()(Benefits);