const navigateTo = (selector: string) => {
	const section = document.querySelector(selector);
    if (!section) return;

    let scrollTop = (window.pageYOffset || section.scrollTop) - 138 //minus menu height


    window.parent.scrollTo({
        top: section.getBoundingClientRect().top + scrollTop,
        behavior: 'smooth'
    });
};

const getMailTo = () => {
    return "mailto:michaela@fortesinteractive.com"
}

const openNews = (language: string) => {
    if(language === "cs") window.open("https://www.fortesinteractive.com/cs/blog")
    else window.open("https://www.fortesinteractive.com/blog")
}

export { navigateTo, getMailTo, openNews }