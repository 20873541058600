import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Grid from '@mui/material/Grid/Grid';

import { ReactComponent as FortesLogo } from '../assets/fortes-interactive-logo.svg';
import { ReactComponent as DieboldLogo } from '../assets/diebold-nixdorf-logo.svg';
import misa from '../assets/misa.png';
import michaela from '../assets/michaela.png';
import Chip from '../components/Chip';
import { Box, Button } from '@mui/material';

interface IState {
}

interface IProps extends WithTranslation {
}

class Contacts extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

    openFortes(lang: string) {
        if(lang === 'cs') window.open("https://www.fortesinteractive.com/cs")
        else window.open("https://www.fortesinteractive.com/")
    }

    openDiebold() {
        window.open("https://www.dieboldnixdorf.com/en-us/")
    }

	render() {	
		const t = this.props.t;
        const ns = 'main';
        const contactSx = { display: 'flex', flexDirection: 'column', rowGap: '8px', margin: 'auto', justifyContent: 'flex-end' };

		return <Block color='primary.main' fullHeight={true} rounded sx={{ rowGap: { xs: '20px', lg: '80px', xl: '120px'}, justifyContent: 'flex-start'}}>

            <Box sx={{ mt: { lg: 0, xl: '60px' }}}>
                <Heading lightBg={false}>
                    {t('contacts', {ns})}
                </Heading>
            </Box>

            <Grid container spacing={4} sx={{ flexGrow: 0, height: 'fit-content', p: { xs: '10px', sm: '10px', md: 0 } }}>
                <Grid item container xs={12} lg={6} columnSpacing={1} rowSpacing={4}>
                    <Grid item sm={2.5}>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Chip label={t('manufacturer', {ns})} />
                            </Grid>
                            <Grid item xs={12}>
                                <Chip label='FORTES Interactive' />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={3.5} className='contact-image'>
                        <img src={misa} alt='Michaela Fanglová' />
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} sx={contactSx}>
                        <Chip label='Michaela Fanglová' size='medium' />
                        <Chip label='+420 723 664 861' size='medium' />
                        <Chip label='michaela@lotusfoil.com' size='medium' sx={{ fontSize: '10px' }} />
                        <Button onClick={() => this.openFortes(this.props.i18n.language)}>
                            <FortesLogo style={{ marginTop: '30px', alignSelf: 'center' }}/>
                        </Button>
                    </Grid>
                </Grid>

                <Grid item container xs={12} lg={6} columnSpacing={1} rowSpacing={4}>
                    <Grid item sm={2.5}>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Chip label={t('partner', {ns})} />
                            </Grid>
                            <Grid item xs={12}>
                                <Chip label='Diebold Nixdorf' />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={3.5} className='contact-image'>
                        <img src={michaela} alt='Michaela Janková' />
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} sx={contactSx}>
                        <Chip label='Michaela Janková' size='medium' />
                        <Chip label='michaela.jankova@dieboldnixdorf.com' size='medium' sx={{ fontSize: '10px' }} />
                        <Button onClick={this.openDiebold}>
                            <DieboldLogo style={{ marginTop: '60px', alignSelf: 'center' }} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(Contacts);