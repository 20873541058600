import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import { Button,Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { withTranslation, WithTranslation } from 'react-i18next'
import hoistStatics from 'hoist-non-react-statics';
import IModule from "../../system/IModule"
import { setPageTitle } from '../../App'

interface IProps extends WithTranslation {

}

interface IState {}

@IModule
class Dashboard extends React.Component<IProps, IState, WithTranslation> {
	
	protected reloadInterval: any | null = null;
	

	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(this.getLocale()+".title", {ns:this.getLocale()})) || "???",
			route: "/main",
			icon: <HomeIcon />,
			weight : 0
		};
	}

	public static getLocale(): string {
        return 'main';
    }
	
	constructor(props: IProps) {
		super(props);

		// EXPL: V konstruktoru je možno inicializovat stav přímo, bez setState, protože nepotřebujeme re-rendering.
		// Velmi často se zde nastavuje stav z props - to je hlavní use-case! Že v props dáme nějakou defaultní hodnotu,
		// ale ta se pak může měnit, a tak z toho zde uděláme stav. Např. this.state = { val: this.props.val };
		this.state = {};
	}

	// EXPL: Hook po vytvoření komponenty, před prvním renderingem. Je to něco jako OnLoad().
	async componentDidMount() {
		setPageTitle(this.props.t( Dashboard.getLocale()+".title", { ns: Dashboard.getLocale() }));
	}

	// EXPL: Hook před zničením komponenty. Zde bychom měli zrušit všechny timeIntervaly, requesty a podobné věci.
	componentWillUnmount() {
		clearInterval(this.reloadInterval);
		this.reloadInterval = null;
	}


	render() {
		const t = this.props.t;
		const ns = Dashboard.getLocale();

		return (<div className="dashboard">
		
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={4}>
				<Grid item container xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >					
					<Typography variant="h2"></Typography>
				</Grid>
				
				<Grid item xs={6} md={3}>
				</Grid>
			</Grid>
		</Box>
		
		</div>);
	}
}

export default hoistStatics(withTranslation()(Dashboard), Dashboard)