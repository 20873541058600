import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { SolidButton, TextButton } from '../components/Button';
import Block from '../components/Block';
import Heading from '../components/Heading';
import Description from '../components/Description';
import { Box } from '@mui/material';

import { Parallax } from 'react-scroll-parallax';
import introRaster from '../assets/intro.png';
import { ReactComponent as IntroVectorPink } from '../assets/intro_shapes_pink.svg';
import { ReactComponent as IntroVectorBlue } from '../assets/intro_shapes_blue.svg';
import { navigateTo } from '../system/Util';

interface IState {
}

interface IProps extends WithTranslation {
}

class Intro extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';
        const bgSx = { maxHeight: '709px', maxWidth: '80%', position: 'absolute', bottom: 0, right: 0, zIndex: -1 } as React.CSSProperties;
        const parallaxSx = { ...bgSx, width: '100%' } as React.CSSProperties;

		return <Block sx={{ height: '100vh', position: 'relative', justifyContent: { xs: 'flex-start', md: 'center' }, overflow: 'hidden' }}>

            <Box sx={{mt: '64px'}}>
                <Heading 
                    offsetIcon 
                    sx={{ minWidth: { md: '70%' }, width: { md: '70%' } }}
                    firstSlashStart={{x: 0, y: 0}}
                    secondSlashStart={{x: 0, y: 0}}
                >
                    {t("title", {ns})}
                </Heading>
            </Box>
            
            <Description sx={{ width: { md: '50%' }, mt: '42px' }}>
                {t("pitch", {ns})}
            </Description>

            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'flex-start',  columnGap: '16px', rowGap: '16px' }}>
                <SolidButton onClick={() => navigateTo('#function')}>{t("what-lotus-foil-does", {ns})}</SolidButton>
                <TextButton onClick={() => navigateTo('#usage')}>{t("where-lotus-can-be-used", {ns})}</TextButton>
            </Box>

            <Box sx={{ width: '100%', height: 'calc(100% + 60px)', position: 'absolute', top: 0, left: 0, zIndex: -1, bgcolor: 'background.paper' }} />
            
            <Parallax className='parallax' style={parallaxSx} translateX={['0vw', '10vw', 'easeInQuad']} translateY={['0vw', '-10vw', 'easeInQuad']}>
                <IntroVectorPink style={{ ...bgSx, width: 'unset', height: 'unset' }} />
            </Parallax>
            <Parallax className='parallax' style={parallaxSx} translateX={['0vw', '-10vw', 'easeInQuad']} translateY={['0vw', '10vw', 'easeInQuad']}>
                <IntroVectorBlue style={{ ...bgSx, width: 'unset', height: 'unset' }} />
            </Parallax>
            <Parallax className='parallax' style={parallaxSx}>
                <img src={introRaster} alt='' style={bgSx} />
            </Parallax>
        </Block>;
	}
}

export default withTranslation()(Intro);