import { Box } from '@mui/material';
import React from 'react'

interface IState {
}

interface IProps {
    children?: React.ReactNode,
    lightBg: boolean,
}

class Emphasis extends React.Component<IProps, IState> {

    static defaultProps: IProps = { lightBg: true };

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {
        const fgColor = !this.props.lightBg ? 'primary.main' : 'primary.contrastText';
        const bgColor = this.props.lightBg ? 'primary.main' : 'primary.contrastText';

		return <Box component='span' sx={{
                display: 'inline',
                p: { xs: '0 0.3em 0.05em', md: '0 .5em 0.05em'},
                backgroundColor: bgColor,
                color: fgColor,
                borderRadius: '1em' }}>
                
                    {this.props.children}
            </Box>;
	}
}

export default Emphasis;