import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react'

interface IState {
}

interface IProps {
    children?: React.ReactNode,
    lightBg: boolean,
    sx?: SxProps,
}

class Description extends React.Component<IProps, IState> {

    static defaultProps: IProps = { lightBg: true };

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {
        const fgColor = this.props.lightBg ? 'primary.main' : 'primary.contrastText';

		return <Box sx={{ width: 'auto', mt: { xs: '55px', md: '90px'}, mb: '42px', ...this.props.sx }}>
                <Typography variant='body1' color={fgColor}>
                    {this.props.children}
                </Typography>
            </Box>;
	}
}

export default Description;