import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';

import Grid from '@mui/material/Grid/Grid';
import Accordion from '../components/Accordion';
import { SolidButton } from '../components/Button';
import { getMailTo } from '../system/Util';

interface IState {
}

interface IProps extends WithTranslation {
}

class FAQ extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';

		return <Block color='background.default'>

            <Grid container rowSpacing={4}>
                <Grid item md={7}>
                    <Heading>
                        {t('questions-and-answers', {ns})}
                    </Heading>
                    <SolidButton href={getMailTo()} sx={{ fontSize: '0.9em', minHeight: '5vh', minWidth: '10vw', mt: { xs: '55px', md: '90px' }}}>{t('contact-us', {ns})}</SolidButton>
                </Grid>

                <Grid item md={5} maxHeight={"110vh"}>
                    <Accordion sx={{ overflowY: 'auto' }}
                        items={t('FAQ', {ns, returnObjects: true}) ?? []}
                        summaryKey='question' detailsKey='answer' />
                </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(FAQ);