import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Grid from '@mui/material/Grid/Grid';

import utb from '../assets/UTB.png';
import utbEn from '../assets/UTB_en.png'
import croBrno from '../assets/BrnoCeskyRozhlas.png';
import forbes from '../assets/Forbes.png';
import czechCrunch from '../assets/CzechCrunch.png';
import denik from '../assets/Denik.png';

interface IState {
}

interface IProps extends WithTranslation {
}


const utbLink = {
    cs: 'https://www.utb.cz/aktuality-akce/specialni-folie-eliminuje-pritomnost-viru-a-bakterii-na-dotykovych-obrazovkach/',
    en: 'https://www.utb.cz/en/news-events/special-plastic-sheet-to-eliminate-viruses-and-bacteria-on-touch-screens/'
}

const croBrnoLink = 'https://brno.rozhlas.cz/na-vyvoji-samocistici-folie-spolupracuji-chemici-z-vut-specialni-vrstva-brani-8438148';
const forbesLink = 'https://forbes.cz/viry-a-bakterie-na-displeji-maji-smulu-firma-z-brna-vymyslela-samocistici-folii/';
const czechCrunchLink = 'https://cc.cz/2021/07/dotyk-bez-obav-ceska-antimikrobialni-folie-chce-poskytnout-jistotu-cistoty-pri-pouzivani-dotykovych-displeju/';
const denikLink = 'https://www.denik.cz/pribehy-a-rozhovory/brno-samocistici-folie-podnikani-viry-bakterie-20210503.html';


const utbLogo = {
    cs: utb,
    en: utbEn
}
const utbTitle = {
    cs: 'Univerzita Tomáše Bati ve Zlíně',
    en: 'Tomas Bata University in Zlín'
}
const croBrnoTitle = 'Český rozhlas Brno';
const forbesTitle = 'Forbes';
const czechCrunchTitle = 'Czech Crunch';
const denikTitle = 'Deník';

class Press extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';
        const lang = this.props.i18n.language === 'cs' ? 'cs' : 'en';
        const imgWidth = 250;
        const pressImgs: Array<{
            href: string,
            src: string,
            alt: string
        }> = [
            {
                href: utbLink[lang],
                src: utbLogo[lang],
                alt: utbTitle[lang]
            },
            {
                href:croBrnoLink,
                src:croBrno,
                alt:croBrnoTitle
            },
            {
                href:forbesLink,
                src:forbes,
                alt:forbesTitle
            },
            {
                href:czechCrunchLink,
                src:czechCrunch,
                alt:czechCrunchTitle
            },
            {
                href:denikLink,
                src:denik,
                alt:denikTitle
            }
        ]

		return <Block fullHeight rounded color='background.paper' sx={{ m: { xs: '30px 30px 100px', md: '150px 0px 240px'} }}>

            <Heading
                sx={{mb: '30px'}}
            >
                {t('written-about-us', {ns})}
            </Heading>
            <Grid container justifyContent="center" sx={{width:"100%"}}>
            <Grid container 
                sx={{ 
                    width: {xs: "auto", lg:"70vw", xl:"55vw"},
                    display: 'flex', justifyContent: 'center',
                    alignItems: 'center',
                    '& > *': { display: 'flex', justifyContent: 'center' }
                }}
                spacing={4}
            >
                {pressImgs.map(img => 
                    <Grid item xs sx={{mt: '30px'}}>
                        <a target='_blank' rel="noreferrer" href={img.href}>
                            <img src={img.src} width={imgWidth} alt={img.alt} />
                        </a>
                    </Grid>
                )}
            </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(Press);